.layout-product {
  &-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &-feature-container {
    display: flex;
    width: calc(75% - 0.5rem);
    max-width: 1008px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;

    .external-feature-container {
      width: 100%;
      display: flex;
      padding-left: 1rem;
      margin-bottom: 1rem;
    }
  }

  &-side-box {
    margin-top: 70px;
    width: calc(25% - 0.5rem);
    max-width: 322px;
  }
}


@media (max-width: $desktop-min) {
  .layout-product {

    &-container {
      display: flex;
      flex-direction: column;
    }

    &-feature-container {
      width: 100%
    }

    &-side-box {
      margin-top: 0px;
      min-width: 240px;
    }
  }
}


