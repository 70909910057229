.layout-product-accordion-body {
  &-container {
    width: 100%;
    max-width: 1008px;

    // Commented out for swapping to v1 temporary
    // Refer to https://www.webcomponent.designsystem.tech.gov.sg/?path=/story/getting-started-installation--page for reinstallation
    sgds-accordion-item::part(header), sgds-accordion-item::part(base) {
      background-color: inherit;
      border-right: none;
      border-left: none;
    }

    sgds-accordion-item::part(header) {
      font-size: 18px;
    }

    sgds-accordion {
      --accordion-active-color: var(--brand-color, #842467);
    }

    sgds-accordion-item {
      --accordion-item-border-radius: 0;
    }

    sgds-accordion-item p {
      font-size: 1rem;
    }

    ul {
      list-style: disc;
    }

    ul, ol {
      margin-left: 2rem;
      margin-top: 1rem;
    }

    ul > li, ol > li {
      margin-top: 1rem;
      font-size: 1rem;
    }

    p:nth-of-type(n+2) {
      margin-top: 1rem;
    }
  }
}
