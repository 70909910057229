.feature-bread-crumb {
  &-container {
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    margin: 2.25rem 0 -2.25rem 0;
    flex-wrap: wrap;
  }

  &-group-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
  }

  &-text-crumb {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem; /* 100% */
    letter-spacing: 1px;
    text-transform: uppercase;
    align-self: center;
    color: $--link-color;
  }

  &-active {
    color: $--Greys-500;
  }
}
