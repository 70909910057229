.layout-product-default {
  &-container {
    background: $--feature-bg-white url(/assets/img/bg-img.png) top center no-repeat;
  }

  &-enclosing-container {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100%;
    max-width: 1350px;
  }

  &-sub-container {
    margin: 0 2.5rem;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1600px) {
  .layout-product-default {
    &-sub-container {
      justify-content: center;
    }
  }
}

@media screen and (max-width: $tablet-max) {
  .layout-product-default {
    &-sub-container {
      margin: 0 2rem;
    }
  }
}
