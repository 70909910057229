.feature-contact-section {
  &-container {
    display: flex;
    padding: 4rem 8rem;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(98deg, var(--brand-color) 2.51%, var(--brand-color-secondary) 99.54%);
  }

  &-enclosing-container {
    display: flex;
    width: 1350px;

  }

  &-image {
    // same image dimensions as the logo in product navbar
    height: 50px;
    max-width: 12rem;
    object-fit: contain;
  }

  &-left-sub-section,
  &-right-sub-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-left-sub-section {
    width: 341px;
    gap: 2rem;
    flex-shrink: 0;
    align-self: stretch;

    p {
      font-size: 2rem;
      font-weight: 600;
      color: $--default-white;
    }
  }

  &-right-sub-section {
    gap: 3rem;
    flex: 1 0 0;
  }

  &-slogan {
    color: $--feature-bg-white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.5rem;/* 125% */
    margin-bottom: 0px;
  }

  &-cta-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &-icon {
    color: $--default-white;
    font-size: 1.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
  }

  &-icon > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  &-social-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  &-contact-btn,
  &-demo-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: var(--border-, 0.25rem);
    font-family: "Source Sans Pro";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    border: none;
  }

  &-contact-btn:hover,
  &-demo-btn:hover {
    cursor: pointer;
  }

  &-contact-btn {
    padding: 11px 32px;
    background: $--feature-bg-white;
    color: var(--brand-color, $--default-black);
  }

  &-demo-btn {
    padding: 12px 31px;
    color: $--default-white;
    background-color: transparent;
    border: 1px solid $--default-white;
  }
}

@media screen and (min-width: 1600px) {
  .feature-contact-section {
    &-container {
      justify-content: center;
    }
  }
}

@media screen and (max-width: $mobile-max) {
  .feature-contact-section {
    &-container {
      padding: 2rem;
    }
  }
}

@media screen and (max-width: $tablet-max) {
  .feature-contact-section {
    &-container {
      padding: 3rem 2rem;
    }
  }
}
