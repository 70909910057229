.blink {
  animation: opacity 3s ease-in-out infinite;
  opacity: 1;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}
