@media screen and (max-width: 768px) {
  .search-box-defined-width {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .search-box-defined-width {
    width: 350px;
    ::placeholder {
      font-size: 16px;
    }
  }
}
