#feedback{
    .sgds-buttons{
        .sgds-button{
            border-color: hsl(0, 0%, 84%);
            &:not(:last-child){
                margin-right: 1rem;
            }
            span{
                margin-right: 0.2rem;
            }
        }
    }
}

.feedback-banner {
  position: relative;
  z-index: 1;
  a.feedback-banner-link {
    text-decoration: underline;
  }
}
