.feature-hero-banner-headers {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    padding: 0;
  }

  &-container ul > li, &-container ol > li  {
    padding-left: 1rem;
    margin-left: 2rem;
  }

  &-container ul > li {
    list-style-type: disc;
  }

  &-title {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.9375rem; /* 100% */
    letter-spacing: 1px;
    background: linear-gradient(98deg, var(--brand-color) 2.51%, var(--brand-color-secondary) 99.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
  }

  &-descriptor {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;
    margin-bottom: 0;
  }

  &-button-get-started,
  &-button-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: var(--border-radius, 4px);
  }

  &-button-get-started {
    padding: 0.875rem 2rem;
    background: var(--brand-color, $--default-black);
    border-color: transparent;
    color: $--default-white;
    transition: text-decoration-color 300ms, text-underline-offset 300ms;
    text-underline-offset: 0.5em;
    text-decoration: underline 1px rgba(white, 0) !important;

    &:hover{
      color: $--default-white;
      text-decoration-color: rgba(white, 1) !important;
      text-underline-offset: 4px;
    }
  }

  &-button-cta {
    padding: 0.8125rem 1.9375rem;
    border: 1px solid var(--brand-color, $--default-black);
    color: var(--brand-color, $--default-black);
    background: $--feature-bg-white;
    text-decoration: none !important;
  }

  &-button-cta:hover {
    text-decoration: none;
    background-color: color-mix(in srgb, var(--brand-color), black 20%);;
    color: $--default-white;
    border: 1px solid color-mix(in srgb, var(--brand-color), black 20%);
  }

  &-btn-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.875rem;
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: $tablet-min) {
  .feature-hero-banner-headers {
    &-button-get-started,
    &-button-cta {
      max-width: max-content;
    }
  }
}

.badge-container {
  margin-top: -2rem;
}
