.overview-subsection {
  &-container {
    display: flex;
    flex-direction: row;
  }

  &-sub-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: -webkit-fill-available;

    .feature-card-container-default-content {
      border-radius: var(--border-radius, 0.25rem);
      border: $--card-border-grey 1px solid;
    }
  }
}

@media screen and (min-width: $desktop-min) {
  .overview-subsection {
    &-container {
      gap: 0;
    }

    &-sub-container {
      align-items: flex-start;
    }
  }
}
