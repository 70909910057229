.award-card {

  &-container-default-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1008px;
    padding: 1rem 2rem;
    align-items: flex-start;
    gap: 0.5rem;
    flex-shrink: 0;
  }

  &-container-default-content > p {
    margin-bottom: 0 !important;
  }

  &-icon {
    width: auto;
    height: 70px;
    overflow: hidden; // Hide any overflowing parts of the image

    img {
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }

  &-title-icon {
    font-size: 2rem;
    color: var(--brand-color, $--default-black);
  }

  &-title,
  &-descriptor {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 1.125rem;
    font-style: normal;
  }

  &-title {
    color: var(--brand-color, $--default-black);
    font-weight: 600;
    line-height: 1.5rem; /* 133.333% */
    margin-bottom: 0px;
    font-size: 1.25rem;
  }

  &-descriptor {
    color: $--Greys-600;
    font-weight: 400;
    line-height: 1.875rem; /* 166.667% */
    margin-bottom: 1rem !important;
  }

  &-arrow-icon {
    font-size: 1.25rem;
    color: $--link-color;
  }
}

@media screen and (min-width: $desktop-min) {
  .feature-card {
    &-container-default {
      max-width: 985px;
    }
  }
}
