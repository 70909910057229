.layout-product-home {
  &-banner-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.5625rem;
    align-items: center;
  }

  &-banner-image {
    width: 100%;
    border-radius: 1rem;
    box-shadow: 12px 12px 51px 0px #DDE5F0;
  }
}

@media screen and (min-width: $desktop-min) {
  .layout-product-home {
    &-banner-container {
      flex-direction: row;
      justify-content: space-between;
      gap: 0px;

      .feature-hero-banner-headers-container {
        width: 49%;
      }
    }

    &-banner-image-container {
      width: 49% !important;
      max-width: 736px;
    }
  }
}
//remove padding of hero images in tablet, mobile view
.layout-product-home {
  &-banner-image-container {
    &.col.is-12-tablet,
    &.col.is-12-mobile{
      padding:0;
    }
  }
}
