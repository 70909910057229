.layout-product-resources {
  &-container {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
  }

  &-sub-container {
    display: flex;

    .feature-card-container-default {
      height: 100%;
    }
  }
}
