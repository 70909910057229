.feature-card {
  &-container-default {
    display: flex;
    width: -webkit-fill-available;
    flex-direction: column;
    padding: 2rem;
    align-items: flex-start;
    gap: 1rem;
    border-radius: var(--border-radius, 0.25rem);
    border: $--card-border-grey 1px solid;
    background: $--card-background-white;
    box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.09);
  }

  &-container-default-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1008px;
    padding: 1rem 2rem;
    align-items: flex-start;
    gap: 0.5rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    background: $--card-background-white;
    /* feature sm shadow */
    box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.09);

    ul > li {
      list-style-type: disc;
    }

    ul > li, ol > li {
      padding-left: 1rem;
      margin-left: 2rem;
    }

    ul > li:nth-child(n+2),
    ol > li:nth-child(n+2) {
    margin-top: 1rem;
}
  }

  &-container-default-content > p {
    margin-bottom: 0 !important;
  }

  &-icon {
    width: auto;
    height: 70px;
    overflow: hidden; // Hide any overflowing parts of the image

    img {
      width: 100%;  // Ensure the image scales to the container's width (92px)
      height: 100%; // Ensure the image scales to the container's height (70px)
      object-fit: cover; // Crop the image to fit within the container dimensions
    }
  }

  &-clickable {
    cursor: pointer;
  }

  &-title-icon {
    font-size: 2rem;
    color: var(--brand-color, $--default-black);
  }

  &-title,
  &-descriptor {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 1.125rem;
    font-style: normal;
  }

  &-title {
    color: var(--brand-color, $--default-black);
    font-weight: 600;
    line-height: 1.5rem; /* 133.333% */
    margin-bottom: 0px;
    font-size: 1.25rem;
  }

  &-title-link {
    color: $--link-color;
  }

  &-descriptor {
    color: $--Greys-600;
    font-weight: 400;
    line-height: 1.875rem; /* 166.667% */
    margin-bottom: 1rem !important;
  }

  &-arrow-icon {
    font-size: 1.25rem;
    color: $--link-color;
  }
}

@media screen and (min-width: $desktop-min) {
  .feature-card {
    &-container-default {
      max-width: 985px;
    }
  }
}
