.sgds-footer {
  strong {
    color: white;
  }
  .contribute {
    a:link, a:visited {
      color: white;
      padding-right: 0;
    }
  }
}
