.layout-product-getting-started {
  &-container {
    display: flex;
    max-width: 1008px;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
