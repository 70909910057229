.feature-stepper {
  &-container {
    display: grid;
    gap: 1rem;
  }

  &-step-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &-step-sub-container {
    display: flex;
    flex-direction: column;

    ul {
      list-style: disc;
    }

    ul, ol {
      margin-left: 2rem;
      margin-top: 1rem;
    }

    ul > li, ol > li{
      margin-top: 1rem;
    }
  }

  &-step-title {
    color: var(--brand-color, $--default-black);
    font-size: 1.25rem;;
    font-weight: 700;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
  }

  &-step-description {
    font-weight: 400;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
  }

  &-index-circle,
  &-index-outer-circle {
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex
  }

  &-index-circle {
    color: var(--brand-color, $--default-black);
    background-color: $--card-background-white;
    font-size: 1.25rem;
    height: 1.5rem;
    width: 1.5rem;
    font-weight: 600;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
  }

  &-index-outer-circle {
    background-color: $--default-white;
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
  }
}
