html {
    scroll-behavior: smooth;
  }

html,
body {
height: auto;
}

body {
display: flex;
flex-direction: column;
}

main {
  flex: 1 0 auto;
}
