.feature-team {
  &-container {
    display: flex;
    padding: 1.5rem;
    align-items: center;
    gap: 1rem;
    border-radius: var(--border-radius, 0.5rem);
    border: 1px solid $--card-border-grey;
    background: $--card-background-white;
    width: 100%;

    &-small {
      display: flex;
      width: 324px;
      align-items: center;
      gap: 0.5rem;
      border-radius: var(--border-radius, 0.25rem);
    }
  }

  &-avatar {
    width: 6.25rem;
    height: 6.25rem;
    flex-shrink: 0;
    border-radius: 50%;

    &-small {
      width: 3rem;
      height: 3rem;
      flex-shrink: 0;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &-name {
    color: $--Greys-600;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4375rem; /* 127.778% */
  }

  &-position {
    color: $--Greys-500;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem; /* 128.571% */
    margin-bottom: 1rem;

    &-small {
      color: $--Greys-500;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: "Source Sans Pro";
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem; /* 128.571% */
    }
  }

  &-hyperlink {
    font-size: 1.125rem;
  }

  &-product {
    color: $--link-color;
  }
}
