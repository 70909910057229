@import "sass/abstract/variables";
@import "sass/abstract/resets";
@import "~sgds-govtech/sgds/sass/sgds";
@import "sass/base/typography";
@import "sass/base/devportal/all";
@import "sass/base/anchor";
@import "sass/layout/all";
@import "sass/components/all";
@import "sass/subcomponents/all";
@import "sass/pages/home";
@import "sass/pages/about-us/grid";
@import "sass/pages/blog-group/blog-group";
@import "sass/pages/feedback/feedback";
@import "sass/pages/govbuy/all";
@import "sass/pages/sg-tech-stack/sg-tech-stack";
@import "sass/vendor/all";
@import "sass/section/all";

.sgds-notification a.announcement-link {
  color: $blue;
}

a.external-link::after {
  display: inline-block;
  font-family: sgds-icons;
  content: "\e95c";
  padding-left: 0.25rem;
  font-weight: 400;
}

[v-cloak] {
  display: none;
}

.hero-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .cta {
    a {
      width: 100%;
    }
  }
}

.content img,
.content iframe {
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.content iframe {
  width: 100%;
  height: 25rem;
}

.card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.sgds-tabs.has-product ul {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  border: 0;
  width: 100%;
  li {
    width: 97%;
    a {
      width: 100%;
    }
  }
}

.sgds-tabs.is-toggle-product a {
  border: 1px solid #d6d6d6;
  margin-bottom: 0;
  position: relative;
  margin-left: 2px;
  width: 300px;
  transition: all 0.2s;
  &:hover {
    background-color: #f0f0f0;
  }
}

.sgds-tabs.is-toggle-product li.is-active a {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  z-index: 1;
}

.bg-edge {
  margin: -7px 0;
}

#curve {
  position: relative;
  bottom: 0;
  width: 100%;
}

#curve path {
  fill: rgb(68, 78, 121);
}

#bgcurve {
  position: relative;
  bottom: 0;
  width: 100%;
}

#bgcurve path {
  fill: rgb(255, 248, 220);
}

.home-cta .sgds-card-button {
  background-color: #0076d6;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  color: #484848;
  transition: all 0.3s;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 2px 3px 5px gray;
    background-color: #208de6;
  }
  &:active {
    transform: translateY(0);
    background-color: #0076d6;
  }
}

// Keep main CTA buttons same height
.home-cta .col {
  display: flex;
  & a {
    display: flex;
  }
}

.final-cta {
  a {
    color: white;
  }
}

.idea-sprint-poster {
  max-width: 45%;
}

.sgds-modal .sgds-modal-card-title {
  flex-shrink: 1;
}

.sgds-modal.is-maxmediumwidth .sgds-modal-card {
  max-width: 600px;
}

@media screen and (max-width: 1215px) {
  .idea-sprint-poster {
    max-width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .idea-sprint-poster {
    min-width: 100%;
  }
}

#stack-2020-banner {
  transition: all 0.3s;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.5);
  }
  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
}

@media screen and (min-width: 1023px) {
  .small-viewport-social-media-buttons {
    display: none;
  }
}
.social-button {
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
  color: $body-color;
}
.social-button:hover {
  color: #0076d6;
}

#social-media-btns-container {
  position: sticky;
  top: 0;
}

#wog--sentiments-content .wog--tabbed-button p {
  font-size: 14px;
  line-height: 40px;
}

.hidden {
  visibility: hidden;
}
