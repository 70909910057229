$section-highlights-space: 10%;

@mixin sgts-li-icon($image) {
  background-image: url($image);
  background-repeat: no-repeat;
  background-position: left bottom;
  height: 20px;
  padding-left: 24px;
  padding-top: 0px;
}

@mixin sgts-layer-mixin($borderColor, $background-color) {
  @include until($tablet) {
    .sgds-container.is-fluid {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    ul {
      @include until($tablet) {
        align-items: flex-start;
        justify-content: space-around;
        margin: auto;
      }
    }

  }

  background-color: $background-color;
  border: 3px dashed $borderColor;
  border-radius: 4px;
}

.live {
  @include sgts-li-icon("/assets/icons/oval_green.svg");
}

.work_in_progress {
  @include sgts-li-icon("/assets/icons/oval_orange.svg");
}

.planned {
  @include sgts-li-icon("/assets/icons/oval_blue.svg");
}

.legend {
  align-items: center;

  @media screen and (min-width: 769px){
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (min-width: 551px) and (max-width: 768px) {
    justify-content: flex-start;
  }

  .live {
    margin-left: 7px;
  }
  .planned {
    @media screen and (max-width: 319px) {
      margin-left: 70px !important;
    }
    @media screen and (min-width: 320px) and (max-width: 360px) {
      margin-left: 4px;
    }
    // For Desktop View
      margin-left: 24px;
  }

  .work_in_progress {
    @media screen and (min-width: 280px) and (max-width: 550px) {
      margin-left: 70px;
    }
    @media screen and (min-width: 551px) and (max-width: 768px) {
      margin-left: 24px;
    }
    @media screen and (min-width: 768px) and (max-width: 1215px) {
      margin-left: 70px;
    }
    // For Desktop View
      margin-left: 24px;
  }
}

.service-layer {
  @include sgts-layer-mixin(#0161af, #e2eff8);
}

.base-layer {
  @include sgts-layer-mixin(#0161af, #e2eff8);
  border-bottom: none;
}

.hosting-layer {
  @include sgts-layer-mixin(#98a2b3, #e4e7ec);
  padding-top: 22px;
  padding-bottom: 31px;

  .row {
    justify-content: space-between;
  }

  .host-rows {
    @include until($desktop) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 42px;
    }

    .sub-host-rows {
      @include until($desktop) {
        display: flex;
        flex-direction: column;
        width: calc(100% - (#{$section-highlights-space} / 2));

      }
    }
  }

  .title-col {
    padding-left: 674px !important;

    @media screen and (min-width: 1024px) and (max-width: 1215px) {
      padding-left: 346px !important;
    }
    @media screen and (min-width: 1216px) and (max-width: 1407px) {
      padding-left: 500px !important;
    }
    @include until($desktop) {
      padding-left: 0px !important;
      margin-bottom: -35px !important;
    }

  }

  .width {
    width: auto !important;
  }

  .host-box {
    width: auto;
    border: 1px solid #667085;
    box-sizing: border-box;
    border-radius: 4px;
    color: #667085;
  }

  p {
    color: #667085;
  }
}

.card-grid-container {
  &.sg-tech-stack {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-auto-rows: 1fr;
    column-gap: 16px;
    row-gap: 0;

    @media screen and (max-width: 900px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include until($tablet) {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      row-gap: 16px;
    }

    .sgds-card-header {
      background: none;
    }

    // card header to be center in desktop view and left of mobile view
    .sgds-card-header-title {
      color: black;

      @include until($tablet) {
        justify-content: left !important;
        padding-left: 24px;
      }
      @media screen and (min-width: 280px) and (max-width: 319px) {
        padding-left: 5px;
        }
    }

    .sgds-card-content {
      padding: 0px 24px 24px 24px !important;

      ul {
        list-style-type: none;
        display: grid;
        grid-template-rows: repeat(4, min-content);
        grid-auto-flow: column;


        @media screen and (max-width: 768px) {
          display: list-item;
        }
      }

      li {
        font-weight: bold !important;
        list-style-type: none;
        position: relative;
        margin-bottom: 24px !important;
        //Mobile Size below iphone SE (226px)
        @media screen and (max-width: 330px) {
          margin-bottom: 30px !important;
        }
      }
    }

    //sgds-card-ul-hz, to provide horizontal UI/LI
    .sgds-card-ul-hz {
      a li {
        text-decoration: underline;
      }

      ul {
        justify-content: space-evenly !important;
        flex-wrap: wrap;

        @media screen and (max-width: 900px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .sgds-card .sgds-card-content:last-child {
      border-top: none;
    }

    //To make the height to grow as the card have more items.
    .sgds-card {
      height: calc(100% - (#{$section-highlights-space} / 2));

      @include until($tablet) {
        height: auto;
      }
    }

  }
}
