.back-to-the-top-button {
  /* Clean button*/
  border: none;

  background-color: #0161ae;
  border-radius: 50%;
  bottom: 2.75rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  height: 3rem;
  opacity: 1;
  position: fixed;
  right: 1.3rem;
  cursor: pointer;
  transform: scale(1);
  transition: all 200ms cubic-bezier(0.08, 0.52, 0.52, 1);
  visibility: visible;
  width: 3rem;
  z-index: 99;

  &-hidden {
    opacity: 0%;
    visibility: hidden;
    transform: scale(0);
  }
}

.back-to-the-top-button:hover {
  background-color: #2379be;
}

.product-login-button {
  border-style: none !important;
  justify-content: center !important;
}
