.feature-h2-p {
  &-h2,
  &-p {
    line-height: 1.875rem;
    font-style: normal;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
  }

  &-h2 {
    color: var(--brand-color, $--default-black);
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  &-p {
    color: $--Greys-600;
    font-size: 1.125rem;
    font-weight: 400;
  }

  &-container p:nth-of-type(n+2) {
    margin-top: 1rem; /* Adjust the value as needed */
  }

  &-container {
    max-width: 1008px;
  }

  &-container ul > li {
    list-style-type: disc;
  }

  &-container ul > li, &-container ol > li {
    padding-left: 1rem;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  &-container ul, &-container ol {
    margin-top: 1rem;
  }

  &-container > figure {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &-container > img {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}


