.card-grid-container {
    // Added &.stack-banner for carousel
    &.stack-banner {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: 1fr;
    @media screen and (min-width: 1409px) {
      grid-template-columns: 1.5fr 1fr;
      grid-gap: 2rem;
      grid-auto-rows: 1fr;
    }
    @media screen and (max-width: 1215px) {
      grid-template-columns: repeat(2fr, 1fr);
    }
    @include until($tablet) {
      grid-template-columns: 1fr;
    }
  }
  &.home {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-auto-rows: 1fr;
    @media screen and (max-width: 1215px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include until($tablet) {
      grid-template-columns: 1fr;
    }
  }

  .grid-item {
    .banner-img {
      object-fit: contain;
      height: 300px;

      &-stack2022 {
        transform: translateY(-75px);
        display: block;
        margin-left: auto;
        @include until($tablet) {
          transform: unset;
        }
      }
    }
  }
}

.section-hero {
  color: $white;
  &-gradient {
    background: linear-gradient(268.19deg, #00c0f3 -0.09%, $primary 98.08%);
  }
  &-whatsnew-gradient {
    background: #4d4d4f; 
  }
  &-devsurvey-gradient {
    background: #004c95;
  }
  
  // Added for carousel
  height: auto;
  @media screen and (min-width: 915px) and (max-width: 1215px) {
    height: 37.75rem;
  }

  @media screen and (min-width: 809px) and (max-width: 914px) {
    height: 40.75rem;
  }
  @media screen and (min-width: 769px) and (max-width: 808px) {
    height: 46.25rem;
  }
  @media screen and (min-width: 500px) and (max-width: 769px) {
    height: 54.75rem;
  }
  @media only screen and (max-device-width: 480px) {
    height: 60.75rem;
    /* styles for mobile browsers smaller than 480px; (iPhone) */
  }
  @media only screen and (device-width: 768px) {
    height: 50.75rem;
    /* default iPad screens */
  }
  @media only screen and (max-device-width: 280px) {
    height: 70.75rem;
    /* styles for mobile browsers smaller than 480px; (iPhone) */
  }
}

$section-highlights-image-size: 120px;
.section-highlights {
  margin-top: -150px;
  .sgds-card {
    margin-top: calc(#{$section-highlights-image-size} / 2);
    height: calc(100% - (#{$section-highlights-image-size} / 2));
    @include until($tablet) {
      height: auto;
    }
    .sgds-card-image {
      margin-top: calc(#{-$section-highlights-image-size} / 2);
      text-align: center;
      img {
        margin-bottom: 0;
        max-width: $section-highlights-image-size;
      }
    }
    .sgds-card-content {
      flex-grow: 1;
      h3 {
        margin-bottom: 0;
      }
    }
  }
}

.section-newsletter {
  .card-grid-container {
    padding: 1.25rem;
    border-radius: $radius;
    background-color: hsl(222, 52%, 95%);
    .grid-item {
      img {
        object-fit: contain;
        width: 144px;
      }
    }
  }
}

article {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  img {
    margin: 0;
  }
}

// Added for carousel
.banner {
  &.sgds-section {
    padding-top: 3rem !important;
    // @media screen and (max-width: 1405px) {
    //   padding-top: 2rem !important;
    // }
    @media screen and (max-width: 1220px) {
      padding-top: 2rem !important;
    }
    @include until($tablet) {
      //padding-top: 0rem !important;
      padding: 3rem 1.5rem;
    }
  }

}
// Added for carousel
.sgds-container {
  &.carousel {
    padding-left: calc(var(--global-alignment) * var(--global-alignment-multiplier-xl)) !important;
    padding-right: calc(var(--global-alignment) * var(--global-alignment-multiplier-xl)) !important;
    @include until($tablet) {
      padding-left: calc(var(--global-alignment) * var(--global-alignment-multiplier-lg)) !important;
      padding-right: calc(var(--global-alignment) * var(--global-alignment-multiplier-lg)) !important;
    }
    @media only screen and (max-device-width: 280px) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
}

