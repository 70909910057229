.feature-pills {
  &-container, &-container-active {
    display: inline-flex;
    height: 2rem;
    padding: var(--border-radius, 0.5rem) 1rem;
    align-items: center;
    gap: 0.25rem;
    flex-shrink: 0;
    border-radius: var(--border-radius, 0.5rem);
    background: $--card-background-white;
    text-decoration: none !important;
    color: var(--brand-color, $--default-black);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem; /* 200% */
    white-space: nowrap;
  }

  &-login {
    border-radius: var(--border-radius, 0.5rem);
    border: 1px solid $--link-color;
    background: rgba(15, 98, 254, 0.05);
    color: $--link-color;
  }

  &-container:hover {
    background: color-mix(in srgb, var(--brand-color) 10%, transparent);
    color: var(--brand-color, $--default-black);
  }

  &-container-active {
    background: var(--brand-color, $--default-black);
    color: $--default-white;
  }

  &-login:hover {
    border-radius: var(--border-radius, 8px);
    border: 1px solid $--link-color;
    background: $--link-color;
    color: $--default-white;
  }
}
