.techstack-box {
  display: flex;
  padding: 1rem 2rem;
  align-items: flex-start;
  gap: 1.25rem;
  border-radius: 0.5rem;
  /* feature sm shadow */
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.01), 0px 2px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.09);
  background-color: $--card-background-white;
  border: double 2px transparent;
  background-image: linear-gradient($--card-background-white, $--card-background-white), linear-gradient(98deg, var(--brand-color) 2.51%, var(--brand-color-secondary) 99.54%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
