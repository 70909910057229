$body-color: #323232;
$primary: hsl(207, 99%, 35%);
$sgds-font-path: "../../node_modules/sgds-govtech/fonts";
$family-sans-serif: "Source Sans Pro", BlinkMacSystemFont, -apple-system, "Segoe UI",
"Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

$radius: 4px;

//sgds card
$card-radius : 4px;
$card-shadow : 0;
$card-hover: hsl(204, 100%, 99%);

//h1.display
$size-0: 3.5rem !default;
$size-0-lh: 4rem !default;
$size-0-mobile-display: 3.5rem !default;
$size-0-mobile: 2.75rem !default;

//h1
$size-1: 3rem !default;
$size-1-lh: 3.5rem !default;

//h2
$size-2: 2.5rem !default;
$size-2-lh: 3rem !default;
$size-2-mobile: 2rem !default;

//h3
$size-3: 2rem !default;
$size-3-lh: 2.5rem !default;
$size-3-mobile: 1.75rem !default;

//h4
$size-4: 1.5rem !default;
$size-4-lh: 2rem !default;
$size-4-mobile: 1.5rem !default;

//p
$size-7: 1.125rem !default;
$size-7-lh: 1.6875rem !default;
$size-7-lh-content: 2.2rem !default;

// product brand colors
$var-element:'--';

  $product-color-maps: (
    "adex":(
      pri: #237b88,
      sec: #234b88
    ),
    "aisay": (
      pri: #9D1DF8,
      sec: #00012C
    ),
    "aibots": (
      pri: #364fc7,
      sec: #3b5bdb
    ),
    "analytics-gov": (
      pri: #0088ce,
      sec: #5abd6f
    ),
    "apex-cloud": (
      pri: #2A3178,
      sec: #00718F
    ),
    "apex-marketplace":(
      pri: #2A3178,
      sec: #00718F
    ),
    "automated-baseline-log-review":(
      pri: #0161AF,
      sec: #0161AF
    ),
    "bob": (
      pri: #492ff5,
      sec: #f9d949
    ),
    "bookingsg": (
      pri: #633ab3,
      sec: #4372d6
    ),
    "central-accounts-management": (
      pri: #151c64,
      sec: #50d6fc
    ),
    "checkwho": (
      pri: #0b3d8c,
      sec: #008578
    ),
    "cloak": (
      pri: #4f759f,
      sec: #346497
    ),
    "cloud-file-transfer": (
      pri: #344054,
      sec: #667185
    ),
    "cloud-video-exchange": (
      pri: #0c7ba2,
      sec: #181c48
    ),
    "cloudscape": (
      pri: #7055c7,
      sec: #a880e3
    ),
    "content-website-platform": (
      pri: #0161af,
      sec: #0161af
    ),
    "converge": (
      pri: #606ae0,
      sec: #606ae0
    ),
    "corppass": (
      pri: #17479e,
      sec: #17479e
    ),
    "codescape": (
      pri: #614dd0,
      sec: #8b7dda
    ),
    "crowdtasksg": (
      pri: #0642b6,
      sec: #012353
    ),
    "cstack": (
      pri: #dd3418,
      sec: #811f0e
    ),
    "data-gov-sg": (
      pri: #6054e0,
      sec: #474747
    ),
    "devconsole": (
      pri: #0161af,
      sec: #0161af
    ),
    "diab": (
      pri: #355ce0,
      sec: #0c2e66
    ),
    "digimc": (
      pri: #327c6c,
      sec: #15332c
    ),
    "filesg": (
      pri: #474ba0,
      sec: #ada6d2
    ),
    "formsg": (
      pri: #3b4e9a,
      sec: #038262
    ),
    "gobusiness": (
      pri: #2f959f,
      sec: #2f959f
    ),
    "government-assisted-living-ecosystem": (
      pri: #6e4097,
      sec: #39214f
    ),
    "gathersg": (
      pri: #774592,
      sec: #a956a1
    ),
    "gcc": (
      pri: #257692,
      sec: #1a0a6f
    ),
    "goventry": (
      pri: #0f766e,
      sec: #0f766e
    ),
    "govrewards": (
      pri: #c2410c,
      sec: #c2410c
    ),
    "govsupply": (
      pri: #3d6881,
      sec: #3d6881
    ),
    "govtext": (
      pri: #0059a5,
      sec: #217d7c
    ),
    "govwallet": (
      pri: #c92031,
      sec: #c92031
    ),
    "government-on-commercial-cloud": (
      pri: #257692,
      sec: #1a0a6f
    ),
    "gowhere-suite": (
      pri: #125eec,
      sec: #125eec
    ),
    "i2c": (
      pri: #016db8,
      sec: #19d6e1
    ),
    "identiface": (
      pri: #e70c18,
      sec: #987e80
    ),
    "iras-api-marketplace": (
      pri: #656668,
      sec: #37658b
    ),
    "isomer": (
      pri: #2164da,
      sec: #6d58bb
    ),
    "mas-api": (
      pri: #020f50,
      sec: #a78337
    ),
    "land-transport-datamall": (
      pri: #000000,
      sec: #1ec2ff
    ),
    "lifesg": (
      pri: #3c3c3b,
      sec: #3c3c3b
    ),
    "mash": (
      pri: #00805a,
      sec: #00553c
    ),
    "maestro": (
      pri: #3c30f6,
      sec: #9891fa
    ),
    "myinfo": (
      pri: #c90e15,
      sec: #c90e15
    ),
    "notify": (
      pri: #e40b16,
      sec: #000000
    ),
    "ogre": (
      pri: #3358FF,
      sec: #24B6FF
    ),
    "oobee": (
      pri: #9021a6,
      sec: #9021a6
    ),
    "openattestation": (
      pri: #e47048,
      sec: #e47048
    ),
    "open-digital-platform": (
      pri: #0055B8,
      sec: #0055B8
    ),
    "optical": (
      pri: #000000,
      sec: #000000
    ),
    "osg": (
      pri: #258383,
      sec: #258383
    ),
    "pair": (
      pri: #3a4a63,
      sec: #212a38
    ),
    "pab": (
      pri: #6e4097,
      sec: #39214f
    ),
    "pinpoint": (
      pri: #6354E8,
      sec: #6354E8
    ),
    "postman": (
      pri: #38476d,
      sec: #51669c
    ),
    "purple-a11y": (
      pri: #785ef0,
      sec: #785ef0
    ),
    "redeemsg": (
      pri: #2E3375,
      sec: #646DB5
    ),
    "rmse": (
      pri: #008058,
      sec: #005a8f
    ),
    "searchsg": (
      pri: #1b73cf,
      sec: #000000
    ),
    "seed": (
      pri: #4e42da,
      sec: #1d166f
    ),
    "sense": (
      pri: #111417,
      sec: #111417
    ),
    "singapore-government-design-system": (
      pri: #5a25dc,
      sec: #5a25dc
    ),
    "siac":(
      pri: #1a69df,
      sec: #158aea
    ),
    "sign-with-singpass": (
      pri: #c90e15,
      sec: #c90e15
    ),
    "standardbuild": (
      pri: #0161AF,
      sec: #0161AF
    ),
    "login": (
      pri: #e40b16,
      sec: #000000
    ),
    "ship-hats":(
      pri : #842467,
      sec : #0F62FE
    ),
    "stackops": (
      pri: #2f8341,
      sec: #184422
    ),
    "techbiz": (
      pri: #1e7cae,
      sec: #344054
    ),
    "tradetrust": (
      pri: #2761a9,
      sec: #f07e01
    ),
    "transcribe": (
      pri: #12364f,
      sec: #c44940
    ),
    "trustdocs": (
      pri: #05505C,
      sec: #047481
    ),
    "verify": (
      pri: #c90e15,
      sec: #c90e15
    ),
    "vas": (
      pri: #2d324d,
      sec: #849adf
    ),
    "vica": (
      pri: #0e45e7,
      sec: #0e45e7
    ),
    "vulnerability-management-system": (
      pri: #0161AF,
      sec: #0161AF
    ),
    "wogaa": (
      pri: #056be7,
      sec: #022550
    ),
    "xca": (
      pri: #004A57,
      sec: #022550
    ),
    "xcg": (
      pri: #004A57,
      sec: #004A57
    )
  );

@each $name, $value in $product-color-maps {
  main.#{$name} {
    #{$var-element}brand-color: map-get($value, pri);
    #{$var-element}brand-color-secondary: map-get($value, sec);
  }
}

// SGDS Grid System Modified
// Desktop
$desktop-min: 1216px;

// Tablets
$tablet-min: 768px;
$tablet-max: 1215px;

// Mobile
$mobile-max: 767px;

// Color Theming
$--default-black: #000000;

$--link-color: #0F62FE;

$--default-white: #FFFFFF;
$--card-background-white: #FAFCFF;
$--feature-bg-white: #F6F9F9;

$--card-border-grey: #D0D5DD;

$--Greys-400: #98A2B3;
$--Greys-500: #667085;
$--Greys-600: #344054;


