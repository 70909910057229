.card-grid-container {
  display: grid;
  gap: 2rem;
  align-items: center;
  padding: 1rem 0;

  .grid-item {
    height: 100%;

    .sgds-card {
      height: 100%;
    }
  }

  .sgds-card {
    height: 100%;

    .sgds-card-image-size {
      width: 100%;
      aspect-ratio: 1;
    }
  }

  @include until($tablet) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  &.grid-dynamic-rem {
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));

    // On mobile, the grid will be 2 columns wide
    @include until(600px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include from($tablet) {
    &.grid-17rem {
      grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
    }

    &.grid-25rem {
      grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    }
  }

  &.is-fullwidth {
    width: 100%;
  }
}

.row.is-padding-large .col {
  padding: 1.5rem;
}

.open-source .col {
  display: flex;
}
