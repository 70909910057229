.feature-feedback {
  &-container {
    align-self: flex-end;
    margin-bottom: 3rem;
  }

  &-sub-container,
  &-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-sub-container {
    gap: 0.5rem;
  };
}
