.pilllist-item {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  margin: 0 12px 12px 0;
  text-transform: capitalize;
}

.pilllist-item input[type="checkbox"] {
  display: none;
}

.pilllist-item input[type="checkbox"]:checked+.pilllist-label {
  border: 1px solid #0161af;
  color: #0161af;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  font-weight: bold;
}

.pilllist-label {
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  color: #323232;
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 12px;
  padding-right: 22px;
  text-decoration: none;
}

.pilllist-item input[type="checkbox"]:checked+.pilllist-label .pilllist-icon--checkLight {
  display: flex;
}

.pilllist-item input[type="checkbox"]:checked+.pilllist-label .pilllist-icon--addLight,
.pilllist-label .pilllist-icon--checkLight,
.pilllist-children {
  display: none;
}

.pilllist-label .pilllist-icon {
  margin: 0 0 0 6px;
}

.pilllist-icon {
  background: transparent;
  display: inline-block;
  font-style: normal;
  vertical-align: baseline;
  position: relative;
}
