.feature-section-header {
  &-container {
    display: flex;
    width: 323px;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
  }

  &-title {
    color: $--Greys-600;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem; /* 83.333% */
    letter-spacing: 0.5px;
    margin-bottom: 0;
  }

  &-update {
    color: $--Greys-500;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.6875rem; /* 150% */
    align-self: stretch;
  }
}
