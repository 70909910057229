.feature-stats {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  &-sub-container {
    display: flex;
    width: 25%;
    height: 200px;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &:not(:first-child) {
      border-left: 0.5px solid var(--brand-color, $--default-black);
    }
  }

  &-figures {
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 3.625rem; /* 103.571% */
    letter-spacing: -1px;
    background: linear-gradient(98deg, var(--brand-color) 2.51%, var(--brand-color-secondary) 99.54%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &-descriptor {
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-style: normal;
    color: var(--brand-color, $--default-black);
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.875rem; /* 166.667% */
  }
}

@media screen and (max-width: $mobile-max) {
  .feature-stats {
    &-container {
      flex-direction: column;
    }

    &-sub-container {
      border-left: none !important;
      border-top: 1px solid var(--brand-color, $--default-black);
      align-items: flex-start;
      width: 100%;

      &:last-child {
        border-bottom: 1px solid var(--brand-color, $--default-black);
      }
    }
  }
}

@media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
  .feature-stats {
    &-sub-container {
      width: 50%;

      &:not(:first-child) {
        border-left: 0;
      }
    }
  }

}
