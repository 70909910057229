.feature-nav {
  &-enclosing-container {
    display: none;
    width: 1350px;
    justify-content: space-between;
    height: 3.125rem;
  }

  &-enclosing-container-mobile {
    width: 100%;
  }

  &-mobile-icon {
    color: $--link-color;
  }

  &-enclosing-container-mobile > * {
    font-size: 1.125rem;
  }

  &-mobile-dropdown-menu {
    position: absolute;
    display: none;
    background-color: $--card-background-white;
    margin: 0px -1rem;
    text-transform: capitalize;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    width: 100%;
    border-top: 1px solid $--Greys-400;
    border-bottom: 1px solid $--Greys-400;
    top: 100%;

    &-item {
      padding: 1rem;
    }

    &-item:hover {
      cursor: pointer;
      color: #0F62FE;
    }

    &-item-active {
      padding: 1rem;
      color: #667085;
    }
  }

  &-mobile-login-button {
    border-top: 1px solid $--Greys-400;
    color: var(--brand-color, $--default-black);
  }

  &-mobile-dropdown-menu-active {
    display: block;
  }

  &-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: var(--border-radius, 0.5rem) 1rem;
    align-items: center;
    border-top: 1px solid $--Greys-400;
    border-bottom: 1px solid $--Greys-400;
    background: $--card-background-white;
    top: 0;
    position: sticky;
    z-index: 1;
  }

  &-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
  }

  &-login-container {
    display: flex;
    padding-left: 2rem;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 0.625rem;
  }

  &-brand-logo {
    height: auto;
    max-height: 3.125rem;
    flex-shrink: 0;
    margin: 0 0 0 0;
    cursor: pointer;
    max-width: 12rem;
    object-fit: contain;
  }

  &-mobile-header {
    color: var(--brand-color, $--default-black);
    text-transform: capitalize;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    justify-content: space-between;
    display: flex;
  }

  &-mobile-header:hover {
    cursor: pointer;
  }
}

@media screen and (min-width: 1600px) {
  .feature-nav {
    &-container {
      justify-content: center;
    }
  }
}

@media screen and (min-width: $desktop-min) {
  .feature-nav {
    &-container {
      padding: var(--border-radius, 0.5rem) 2.5rem;
      display: flex;
    }

    &-enclosing-container {
      display: flex;
    }

    &-enclosing-container-mobile {
      display: none;
    }

    &-mobile-dropdown-menu-active {
      display: none;
    }
  }
}

// @media screen and (max-width: $tablet-max) {
//   body {
//     overflow-x: none;
//   }
// }
