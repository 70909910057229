.sidenav-container {
  display: flex;
  align-items: flex-start;
}

.sidenav {
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
  ul.sgds-menu-list {
    margin: 0;
  }
  ul.second-level-nav-div {
    margin: 0;
    list-style: none;
  }
}

.single-level-nav {
  position: sticky;
  top: 0;
  max-height: 100vh;
  overflow-y: auto;
}

.mobile-sidenav {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  a {
    font-size: 1rem;
    color: inherit;
    text-decoration: none;
    &.is-active {
      color: $primary;
    }
  }
  &-toggle.sgds-button {
    width: 100%;
    border-radius: 0;
    justify-content: space-between;
    font-weight: bold;
  }
  ul.mobile-sidenav-menu {
    overflow-y: auto; // for scrolling in mobile
    height: 100vh; // for scrolling in mobile
    &-fit-content {
      height: fit-content; // for ensuring the nav menu height fit the contents
    }
    padding: 0.5rem 1rem;
    li {
      display: flex;
      padding: 4px 0;
      a {
        flex-grow: 1;
      }
    }
    .second-level-nav-div {
      padding-inline-start: 1rem;
    }
  }
}

.sgds-menu-list a {
  color: $body-color;
}
